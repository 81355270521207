import React from "react"
import Layout from "../components/layout"
import Input from "../components/Atoms/input"
import Button from "../components/Atoms/button"
import Fade from "react-reveal/Fade"
import ContactImage from "../images/contact-main.avif"

const Contact = () => {
  return (
    <Layout>
      <input />
        <div className="max-w-7xl mx-auto mt-10 flex xxs:flex-col sm:flex-row">
          <div className="bg-transparent p-10 rounded-xl w-3/4 m-5">
            <h2 className="text-xs opacity-100">Get in touch</h2>
            <h1 className="text-4xl font-bold font-poppins mt-2 xxs:text-lg sm:text-2xl lg:text-4xl">
              Let's work together
            </h1>
            <form className="mt-5" action="https://public.herotofu.com/v1/959e7210-6e79-11ec-b2d5-af79f33d926c" method="post">
              <div className="flex xxs:flex-col sm:flex-row">
                <div className="sm:mr-5 xxs:mr-0 border-2 bg-black">
                  <input class="bg-black text-white rounded p-2" id="name" name="name" type="text" placeholder="Your Name" required></input>
                </div>
                <div className="sm:ml-5 xxs:ml-0 xxs:mt-2 sm:mt-0 border-2 bg-black">
                  <input class="bg-black text-white rounded p-2" id="name" name="email" type="email" placeholder="Your Email" required></input>
                </div>
              </div>
              <textarea
                id="message"
                name="message"
                className="mt-5 w-full bg-black text-white rounded p-5 border-2"
                rows="5"
                placeholder="How can we help you?"
              ></textarea>
              <input
                type="submit"
                value="Send us a message"
                class="bg-black text-white p-2 mt-2 rounded"
                marginClass="mt-5"
              ></input>
            </form>
          </div>
          <div className="w-1/4 overflow-hidden rounded-xl m-5 xxs:hidden sm:block">
            <img
              className="object-cover h-full w-full"
              src={ContactImage}
            ></img>
          </div>
        </div>
    </Layout>
  )
}

export default Contact
